import Swup from 'swup';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupGaPlugin from '@swup/ga-plugin';

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  let navigation;
  let menuTrigger;
  let body;

  function handleDOMContentLoaded() {
    navigation = document.querySelector('#navigation');
    menuTrigger = document.querySelector('#menu-trigger');
    body = document.querySelector('body');

    menuTrigger.addEventListener('click', openCloseMenu);

    const swup = new Swup({
      /*animateHistoryBrowsing: true,*/
      containers: [
        '.main-navigation__menu',
        '.services',
        '.main-content',
        '.js-ajax'
      ],
      plugins: [
        new SwupScriptsPlugin(),
        new SwupHeadPlugin(),
        new SwupScrollPlugin(),
        new SwupGaPlugin()
      ]
    });

    swup.on('clickLink', closeMenu);
  }

  function closeMenu() {
    if (menuTrigger.classList.contains('open')) {
      menuTrigger.classList.remove('open');
      navigation.classList.remove('main-navigation--open');
      body.classList.remove('main-navigation--open');
    }
  }

  function openCloseMenu() {
    if (menuTrigger.classList.contains('open')) {
      menuTrigger.classList.remove('open');
      navigation.classList.remove('main-navigation--open');
      body.classList.remove('main-navigation--open');
    } else {
      menuTrigger.classList.add('open');
      navigation.classList.add('main-navigation--open');
      body.classList.add('main-navigation--open');
    }
  }
})();
